@font-face {
  font-family: "Eudoxus-Sans-Extra-Bold"; /*Can be any text*/
  src: local("EudoxusSans-Bold"), url("../public/fonts/EudoxusSans-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Eudoxus-Sans-Bold"; /*Can be any text*/
  src: local("EudoxusSans-Bold"), url("../public/fonts/EudoxusSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Eudoxus-Sans-Regular"; /*Can be any text*/
  src: local("EudoxusSans-Bold"), url("../public/fonts/EudoxusSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Eudoxus-Sans-Medium"; /*Can be any text*/
  src: local("EudoxusSans-Bold"), url("../public/fonts/EudoxusSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Eudoxus-Sans-Light"; /*Can be any text*/
  src: local("EudoxusSans-Bold"), url("../public/fonts/EudoxusSans-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Eudoxus-Sans-Extra-Light"; /*Can be any text*/
  src: local("EudoxusSans-Bold"), url("../public/fonts/EudoxusSans-ExtraLight.ttf") format("truetype");
}

body {
  font-family: "Eudoxus-Sans-Bold", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
  background-color: #f7f7f7;
  color: #231f20;
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-family: "Eudoxus-Sans-Regular";
  line-height: 180%;
  font-size: 17px;
}

ul {
  font-family: "Eudoxus-Sans-Regular";
  padding-left: 30px;
  padding-right: 20px;
}

ul li {
  margin-top: 20px;
  font-size: 14px;
  padding-left: 10px;
}

.offer-point{
  transition: all 0.1s linear;
  font-size: 40px;
}

.offer-point:hover{
  transform: scale(1.2);
  cursor: pointer;
}

.offer-point::marker{
  content: "🚀";
}

button {
  border-radius: 400px;
  border: 1px solid #231f20;
  background-color: #1ee984;
  color: #231f20;
  padding: 15px 40px;
  cursor: pointer;
  box-shadow: 0px 4px #231f20;
  transition: all 0.1s linear;
  font-size: 16px;
  font-family: "Eudoxus-Sans-Bold";
}

.footer-button {
  border-radius: 0;
  border: none;
  border-top-right-radius: 400px;
  border-bottom-right-radius: 400px;
  border-right: 1px solid rgb(221, 222, 223);
  border-top: 1px solid rgb(221, 222, 223);
  border-bottom: 1px solid rgb(221, 222, 223);
  box-shadow: 0px 0px #231f20;
  background-color: rgb(247,247,247);
  color: #231f20;
  padding: 15px 40px;
  cursor: pointer;
  transition: all 0.1s linear;
  font-size: 16px;
  font-family: "Eudoxus-Sans-Bold";
}

button:hover {
  transform: translate(0, 5px);
  box-shadow: 0px 1px #231f20;
}

.footer-button:hover {
  background-color: rgb(221, 222, 223);
  transform: translate(0, 0px);
  box-shadow: 0px 0px #231f20;
}

footer {
  background-color: #1ee984;
  width: 100%;
  margin-top: 200px;
}

footer input {
  padding: 20px;
  border-radius: 0;
  font-size: 16px;
  border: none;
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #231f20;
  border-radius: 50px;
  width: 80%;
  color: #231f20;
}

input::placeholder {
  color: #231f20;
  font-size: 14px;
  font-family: "Eudoxus-Sans-Medium";
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.line {
  position: absolute;
  top: 50rem;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  width: 100%;
}

.title-intro {
  text-align: center;
  position: relative;
  padding: 0 10px;
}

.grid-background {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  opacity: 0.5;
  width: 100%;
}

h2 {
  font-size: 40px;
  letter-spacing: -0.02em;
}

.title-intro p {
  font-family: "Eudoxus-Sans-Medium";
  max-width: 750px;
  margin: auto;
  padding: 0 10px;
}

.title-intro h2{
  font-size: 56px;
}

.features-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 88px;
  margin-top: 80px;
  flex-wrap: wrap;
}

.feature-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.feature-box p {
  line-height: normal;
  text-align: center;
}

.feature-square {
  border-radius: 10px;
  border: 1px solid #231f20;
  background-color: #f7f7f7;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px #231f20;
  transition: all 0.2s linear;
  cursor: pointer;
}

.feature-square:hover {
  transform: translate(0, -5px);
}

.info-section {
  display: flex;
  flex-direction: row;
  margin-top: 90px;
  width: 100%;
  max-width: 1400px;
  gap: 80px;
  justify-content: space-between;
}

.column-info{
  display: flex;
  margin: auto;
  padding: 0 10px;
  flex-direction: column;
  max-width: 1400px;
}

.row-info{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 0 10px;
  gap: 20px;
  flex-wrap: wrap;
  flex-direction: row;
  max-width: 1400px;
}

.process-box{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.detail-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 400px;
  border: 1px solid #231f20;
  background-color: #1ee984;
  padding: 8px 20px;
  box-shadow: 0px 4px #231f20;
  transition: all 0.1s linear;
  font-size: 12px;
  gap: 10px;
  width: fit-content;
}

.detail-box p {
  margin: 0;
  font-size: 12px;
  font-family: "Eudoxus-Sans-Medium";
}

.left-column {
  display: flex;
  flex-direction: column;
  width: 50%;
  border-radius: 18px;
  padding: 30px;
  box-sizing: border-box;
}

.left-column h2 {
  margin-top: 20px;
  letter-spacing: -0.02em;
}

.right-column {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.right-column img {
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
}

.offers-container {
  display: flex;
  flex-direction: row;
  gap: 64px;
  margin-top: 80px;
  justify-content: center;
  flex-wrap: wrap;
}

.offer {
  border-radius: 18px;
  border: 1px solid #231f20;
  background-color: #f7f7f7;
  width: 300px;
  min-height: 300px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0px 4px #231f20;
  transition: all 0.2s linear;
  cursor: pointer;
}

.offer p{
  font-family: "Eudoxus-Sans-Medium";
}

.offer:hover {
  transform: translate(0, -5px);
}

.lines {
  border-bottom: 1px solid #000000;
  height: 1px;
  width: 20%;
}

.divider {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(30, 233, 132, 0.6);
  border: 2px solid #1ee984;
  width: 100%;
  margin-top: 120px;
  box-sizing: border-box;
  padding: 90px 8px;
  overflow: hidden;
}

.divider img {
  position: absolute;
  top: 0px;
  right: 0;
  opacity: 0.5;
  width: 450px;
  z-index: 0;
}

.divider h2 {
  color: #231f20;
  font-family: "Eudoxus-Sans-Extra-Bold";
}

.divider p {
  color: #231f20;
  z-index: 1;
  text-align: center;
}

.pricing-container {
  position: relative;
  margin-top: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.price-plan {
  border-radius: 16px;
  border: 1px solid #231f20;
  background-color: #f7f7f7;
  width: 300px;
  min-height: 570px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 4px #231f20;
  transition: all 0.2s linear;
  justify-content: space-between;
}

.price-title {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  justify-content: space-between;
}

.plan-divider {
  background-color: #1ee984;
  padding: 2px 0px;
  width: 100%;
  text-align: center;
}

.price-subtitle {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.price-title h2 {
  font-family: "Eudoxus-Sans-Bold";
}

.price-subtitle p {
  font-size: 13px;
  margin-top: 4px;
  font-family: "Eudoxus-Sans-Regular";
}

.discount-container {
  margin-top: 20px;
  box-shadow: 0px 4px #231f20;
}

.tag {
  width: 100%;
  background-color: #ffcb52;
  clip-path: polygon(100% 0, 92% 50%, 100% 100%, 0% 100%, 0 51%, 0% 0%);
  padding: 1px 30px;
  box-sizing: border-box;
}

.tag p {
  font-size: 13px;
}

.footer-container {
  margin: auto;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 30px 0 30px;
  justify-content: center;
}

.footer-end {
  margin-top: 80px;
  border-top: 1px solid #231f20;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
}

.footer-end p {
  font-size: 12px;
}

.footer-end-socials {
  display: flex;
  gap: 20px;
}

.footer-email-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

#footer-email {
  margin: 0;
  font-size: 32px;
}

.footer-right-column {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 0 30px;
  box-sizing: border-box;
}

.navbarComp {
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
  transition: all 0.1s linear;
}

.navbarScrolled {
  background-color: #f7f7f7;
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
  transition: all 0.1s linear;
}

.nav-container {
  display: flex;
  flex-direction: row;
  max-width: 1400px;
  margin: 5px auto;
  padding: 10px 20px;
  align-items: center;
  justify-content: space-between;
}

.logo {
  width: 72px;
}

.navbar-engage {
  border: 1px solid #231f20;
  border-radius: 50px;
  margin: 0;
  padding: 8px 30px;
  font-size: 16px;
  font-family: "Eudoxus-Sans-Bold";
  transition: all 0.1s linear;
  cursor: pointer;
  color: #231f20;
  text-decoration: none;
}

.navbar-engage:hover {
  background-color: #1ee984;
}

.nav-link-container {
  display: flex;
  gap: 30px;
}

.nav-link-topbar {
  color: #231f20;
  font-family: "Eudoxus-Sans-Bold";
  text-decoration: none;
}

.nav-link-topbar:hover {
  color: #231f2070;
  transition: all 0.1s linear;
}

.scroll-container {
  width: 100%;
  margin-top: 90px;
  overflow-x: hidden;
  padding: 20px 10px;
  background-color: #1ee984;
  box-sizing: border-box;
}
.horizontal-scrolling-items {
  display: flex;
  font-size: 62px;
  width: 4600px;
  animation-name: infiniteScroll;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.horizontal-scrolling-items__item {
  white-space: nowrap;
}

.horizontal-scrolling-items__item span {
  -webkit-text-stroke: 2px #231f20;
  color: transparent;
  font-style: italic;
}

@keyframes infiniteScroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

.form-container{
  background-color: #1ee984;
  width: 100%;
  padding: 80px 5px;
  box-sizing: border-box;
}

.form-box{
  display: flex;
  flex-direction: row;
  max-width: 1400px;
  margin: auto;
  align-items: center;
  justify-content: center;
}

.footer-form{
  display: flex;
}

.form-container .form-information{
  width: 50%;
  background-color: #1ee984;
}

.form-input{
  margin-bottom: 10px;
  border: 1px solid rgb(221, 222, 223);
  background-color: rgb(247,247,247);
  border-radius: 8px;
  padding: 20px 16px 20px 16px;
  height: auto;
  font-size: 16px;
  font-family: "Eudoxus-Sans-Regular";
}

.form-input::placeholder {
  color: rgb(180, 180, 180);
  opacity: 1; /* Firefox */
}

.form-input::-ms-input-placeholder { /* Edge 12 -18 */
  color: rgb(180, 180, 180);
}

textarea::placeholder {
  color: rgb(180, 180, 180);
  opacity: 1; /* Firefox */
  font-size: 16px;
}

textarea::-ms-input-placeholder { /* Edge 12 -18 */
  color: rgb(180, 180, 180);
  font-size: 16px;
}

.quote-form{
  background-color: white;
  padding: 40px;
  display: flex;
  flex-direction: column;
  width: 40%;
  box-sizing: border-box;
  border-radius: 14px;
  height: fit-content;
  border: 1px solid #231f20;
  box-shadow: 0px 4px #231f20;
}

textarea {
  width: 100%;
  height: 120px;
  padding: 12px 11px;
  font-family: "Eudoxus-Sans-Regular";
  box-sizing: border-box;
  border: 1px solid rgb(221, 222, 223);
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 16px;
  resize: none;
}

@media screen and (max-width: 900px) {
  .features-container {
    gap: 40px;
    max-width: 450px;
    margin: 80px auto;
    padding: 0 10px;
  }

  .title-intro button {
    margin-top: 0 !important;
  }

  .info-section {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }

  #reverse-info-section {
    flex-direction: column-reverse;
  }

  .left-column {
    width: 100%;
  }

  .right-column {
    width: 100%;
  }

  .nav-link-container {
    display: none;
  }

  .footer-right-column {
    width: 100%;
    align-items: center;
  }

  .form-box{
    flex-direction: column-reverse;
    gap: 40px;
  }

  .form-container .form-information{
    width: auto;
    padding: 0 20px;
  }

  .quote-form{
    width: 90%;
  }
}

@media screen and (max-width: 500px) {
  body {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
  }
  h2{
    font-size: 36px;
  }
  .offer-point:hover{
    transform: scale(1);
  }
  #SEO-illustration {
    max-width: 320px !important;
  }

  .info-section {
    margin-top: 40px !important;
  }

  .logo {
    width: 64px;
  }

  .right-column img {
    max-width: 300px !important;
  }

  .title-intro h2 {
    font-size: 40px;
  }

  #footer-email {
    font-size: 26px;
  }

  .footer-email-info {
    justify-content: center;
  }

  .footer-right-column {
    margin-top: 20px;
  }

  footer input {
    width: 90%;
  }

  .horizontal-scrolling-items__item {
    font-size: 32px;
  }
}
